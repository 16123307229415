import * as React from 'react'

export default function InputSection({ children }) {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          marginTop: '0px',
          display: 'flex',
          maxWidth: '500px',
          minWidth: '340px',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </div>
    </div>
  )
}
